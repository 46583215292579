
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import CarTypeSet from '@/components/CarTypeSet.vue'
import {ICarTypeItem, ICarTypeUpdateItem, ICommentItem} from "@/models/logistics_model";

interface IState {
  list: ICarTypeItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  item?: ICarTypeUpdateItem
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '图片',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '类型名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '简称',
    dataIndex: 'short_name',
    key: 'short_name',
  },
  {
    title: '车长（米）',
    dataIndex: 'car_long',
    key: 'car_long',
    align: 'right'
  },
  {
    title: '车宽（米）',
    dataIndex: 'car_width',
    key: 'car_width',
    align: 'right'
  },
  {
    title: '车高（米）',
    dataIndex: 'car_hight',
    key: 'car_hight',
    align: 'right'
  },
  {
    title: '该车类型最小体积（m³）',
    dataIndex: 'car_min_volume',
    key: 'car_min_volume',
    align: 'right'
  },
  {
    title: '厢长（米）',
    dataIndex: 'standard_long',
    key: 'standard_long',
    align: 'right'
  },
  {
    title: '厢高（米）',
    dataIndex: 'standard_hight',
    key: 'standard_hight',
    align: 'right'
  },
  {
    title: '载方（方）',
    dataIndex: 'standard_volume',
    key: 'standard_volume',
    align: 'right'
  },
  {
    title: '载重（吨）',
    dataIndex: 'standard_weight',
    key: 'standard_weight',
    align: 'right'
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    key: 'create_time',
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '操作',
    key: 'action',
  },
];

export default defineComponent({
  name: 'CarType',
  components: { ImageView, CarTypeSet},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible: false,
      item: undefined,
      searches: {
        status: '',// 状态 0停用，1正常 ,不传全部
        name: ''
      },
    })

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      if (params.status === '') {
        delete params.status
      }
      if (params.name === '') {
        delete params.name
      }
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getCarTypeList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 启用禁用
    const setStatus = async (item: ICommentItem) => {
      state.loading = true
      const res = await ApiLogistics.setCarTypeStatus(item.id,  !item.status)
      message.destroy()
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('设置成功')
        await getList()
      } else {
        message.error(res?.msg || '设置失败');
      }
    }

    const setVisible = (visible: boolean, item: ICarTypeItem | undefined = undefined) => {
      state.visible = visible
      if(visible){
        console.log(item)
        state.item = {
          id: item ? item.id : '',
          name: item ? item.name : '',
          short_name: item ? item.short_name : '',
          car_long: item ? item.car_long : '',
          car_width: item ? item.car_width : '',
          car_hight: item ? item.car_hight : '',
          car_min_volume: item ? item.car_min_volume : '',
          standard_long: item ? item.standard_long : '',
          standard_volume: item ? item.standard_volume : '',
          standard_weight: item ? item.standard_weight : '',
          standard_hight: item ? item.standard_hight : '',
          image: item ? item.image : '',
          describe: item ? item.describe : '',
        }
      }else{
        setTimeout(() => state.item = undefined, 300)
      }

    }

    const onClose = () => {
      setVisible(false)
      getList()
    }

    // 去详情
    const goDetail = (item: ICommentItem, type: 'comment' | 'order' = 'comment') => {
      router.push({path: '/comment/detail', query: {id: item.id}}).then()
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      onSearch,
      resetSearch,
      goDetail,
      setStatus,
      setVisible,
      onClose
    }
  }
})
